<template>
  <div class="dashboard-single-card">
    <div v-if="!hasPermission" class="no-permission">
      <img src="@/assets/images/card-no-permission.png" alt="" />
    </div>
    <div class="full-height">
      <div ref="display" class="full-height"></div>
      <img
        v-if="type === 'edit'"
        class="close-icon"
        @click="deleteHandler"
        src="@/assets/images/dashboard/close-icon.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { debounce } from "lodash";
import { randomStr } from "@/utils/utils.js";

export default {
  name: "dashboard-single-card",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          cardName: "...",
        };
      },
    },
    type: {
      type: String,
      default() {
        return "display";
      },
    },
  },
  data() {
    return {
      hasPermission: true,
      component: null,
      js: null,
      css: null,
      html: null,
      handleResizeDebounce: debounce(() => {
        this.handleResize();
      }, 200),
    };
  },
  mounted() {
    window.addEventListener("error", (event) => {
      console.log("error-1", event.error.message);
      this.$emit("error", event.error.message);
    });

    window.addEventListener("unhandledrejection", (event) => {
      console.log("error-2", event.reason.message);
      this.$emit("error", event.reason.message);
    });
    // const seqId = randomStr();
    if (this.dataset.cardConfig) {
      try {
        this.checkCardPermission();
        this.renderCode();
      } catch (error) {
        console.log("error-3", error);
        this.$emit("error", error);
      }
    }
    const resizeObserver = new ResizeObserver(this.handleResizeDebounce);
    resizeObserver.observe(this.$refs.display);
  },
  methods: {
    checkCardPermission() {
      let curPermission = false;
      const { permissionType, permissionList } = this.dataset;
      if (!permissionType || !permissionList) {
        // 没有返回表示有权限
        return true;
      }
      const permissionListParsed = JSON.parse(permissionList);
      if (permissionType === "role") {
        const currentRoleList = this.$store.getters.roleList.map(
          (item) => item.roleId
        );
        for (let i = 0; i < currentRoleList.length; i++) {
          for (let j = 0; j < permissionListParsed.length; j++) {
            if (permissionListParsed[j] === currentRoleList[i]) {
              curPermission = true;
              break;
            }
          }
        }
      }
      if (permissionType === "point") {
        const currentPermissionList = this.$store.getters.havePermissions;
        for (let i = 0; i < currentPermissionList.length; i++) {
          for (let j = 0; j < permissionListParsed.length; j++) {
            if (permissionListParsed[j] === currentPermissionList[i]) {
              curPermission = true;
              break;
            }
          }
        }
      }
      this.hasPermission = curPermission;
    },
    handleResize() {
      // 一个echarts实例，只需要实例名为myChart
      this.component &&
        this.component.myChart &&
        this.component.myChart.resize &&
        this.component.myChart.resize();
      // 多个echarts实例，在卡片的chartsResize方法中实现
      this.component &&
        this.component.chartsResize &&
        this.component.chartsResize();
    },
    deleteHandler() {
      this.$emit("delete", this.dataset.id);
    },
    // 清除掉style
    removeClassStyle() {
      $(`#custom-card-${this.dataset.cardId}`).remove();
    },
    renderCode() {
      this.splitCode();
      if (this.html !== "" && this.js !== "") {
        if (this.css !== "") {
          console.log("this.css", this.css);
          this.removeClassStyle();
          const style = document.createElement("style");
          style.type = "text/css";
          style.id = `custom-card-${this.dataset.cardId}`;
          style.innerHTML = this.css;
          document.getElementsByTagName("head")[0].appendChild(style);
        }
        const parseStrToFunc = new Function(this.js)();
        parseStrToFunc.template = this.html;
        console.log("parseStrToFunc.template", parseStrToFunc.template);
        const Component = Vue.extend(parseStrToFunc);
        this.component = new Component().$mount();
        this.$refs.display.appendChild(this.component.$el);
        this.hasPermission && this.component.initCharts();
      }
    },
    splitCode() {
      const script = this.getSource(this.dataset.cardConfig, "script").replace(
        /export default/,
        "return "
      );
      const style = this.getSource(this.dataset.cardConfig, "style");
      const template =
        `<div style="height: 100%">` +
        this.getSource(this.dataset.cardConfig, "template") +
        "</div>";

      this.js = script;
      // this.css = style;
      // this.html = template;
      this.css = style.replace(/\.(.*?){/, `.custom-${this.dataset.cardId}{`);
      this.html = template.replace(
        /<div class="(.*?)">/,
        `<div class="custom-${this.dataset.cardId}">`
      );
    },
    getSource(source, type) {
      const regex = new RegExp(`<${type}[^>]*>`);
      let openingTag = source.match(regex);

      if (!openingTag) return "";
      else openingTag = openingTag[0];

      return source.slice(
        source.indexOf(openingTag) + openingTag.length,
        source.lastIndexOf(`</${type}>`)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-single-card {
  position: relative;
  height: 100%;
  width: 100%;
  .full-height {
    height: 100%;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;
  }
  .no-permission {
    position: absolute;
    left: 0;
    width: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    border-radius: 5px;
    font-size: 16px;
    z-index: 1;
    img {
      max-height: 90%;
      max-width: 50%;
    }
  }
}
</style>