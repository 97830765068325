var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-single-card" }, [
    !_vm.hasPermission
      ? _c("div", { staticClass: "no-permission" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/card-no-permission.png"),
              alt: "",
            },
          }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "full-height" }, [
      _c("div", { ref: "display", staticClass: "full-height" }),
      _vm.type === "edit"
        ? _c("img", {
            staticClass: "close-icon",
            attrs: {
              src: require("@/assets/images/dashboard/close-icon.png"),
              alt: "",
            },
            on: { click: _vm.deleteHandler },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }